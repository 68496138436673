import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../actions/user.actions.js';
import { useTranslation } from 'react-i18next';

import HtmlTooltip from '../HtmlTooltip';
import tooltipImg from '../../img/tooltip.png';
import { useDropzone } from 'react-dropzone';
import { List, ListItem, ListItemAvatar, Avatar, IconButton, CircularProgress } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import { AudiotrackSharp as AudioIcon, Delete as DeleteIcon } from '@material-ui/icons';

import { dragAndDropStyleSameBackground } from './shared.styles';
import { useStyles } from './TrainVoicePage.styles';
import { getFilesDuration, getTrainingDuration, showMessageV2 } from '../../utils/page.utils';
import { labelLength } from '../../constants/app.constants.js';
import {
  fetchSubscriptionInfo,
  checkTrainingCompletion,
  uploadTraining,
  uploadModelMethodOne,
  uploadErrorMessage,
} from '../../services/page.services';
import { checkValidLoginStatus } from '../../utils/user.utils';

import { FormControl } from '@mui/material';
import FreeTrainingMessageModal from '../layout/FreeTrainingMessageModal.js';

import addIcon from '../../img/addIcon.png';

function TrainVoicePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const userId = user?.id;
  const isAdmin = user?.subscription?.type === 'ADMIN';
  const isCustom = user?.subscription?.type === 'CUSTOM';

  const [uploadType, setUploadType] = useState('train');
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [externalFile, setExternalFile] = useState({});
  const [label, setLabel] = useState('');
  const [duration, setDuration] = useState(0);
  const [trainingRemainingCount, setTrainingRemainingcount] = useState(0);
  const [trainingInProgress, setTrainingInProgress] = useState(false);
  const [openFreeTrainingModal, setOpenFreeTrainingModal] = useState(false);

  const [needMdx, setNeedMdx] = useState(true);

  useEffect(() => {
    if (userId) (async () => initPage())();
  }, [userId]);

  const trainingDuration = getTrainingDuration(user.subscription?.type, user.subscription?.status === 'past_due');

  const checkTrainingInProgress = async () => {
    const trainings = await checkTrainingCompletion();
    const inProgress = _.some(trainings, { status: 'PENDING' });
    setTrainingInProgress(inProgress);
  };

  const checkRemainingCount = async () => {
    let subscriptionInfo = await fetchSubscriptionInfo();
    dispatch(userActions.updateSubscription(subscriptionInfo));
    setTrainingRemainingcount(subscriptionInfo?.trainingRemaining || 0);
  };

  const initPage = async (isOnSubmit = false) => {
    try {
      if (!isAdmin && !isCustom) {
        await checkTrainingInProgress();
      }
      await checkRemainingCount();
    } catch (e) {
      const message = isOnSubmit ? t('modal.pageLoadFailOnSubmit') : t('modal.pageLoadFail');
      showMessageV2(dispatch, message, { reloadOnClose: true });
    }
    setExternalFile({});
    setFiles([]);
    setLabel('');
    setDuration(0);
  };

  const onDropAccepted = async acceptedFiles => {
    if (checkValidLoginStatus(userId, dispatch)) {
      let max = await getFilesDuration(files);
      const newFileDuration = await getFilesDuration(acceptedFiles);
      const newMaxDuration = max + newFileDuration;
      if (newMaxDuration > trainingDuration.max * 60) {
        showMessageV2(
          dispatch,
          t('trainingTab.modal.exceedMaxDuration', {
            maxDuration: trainingDuration.max,
            minDuration: trainingDuration.min,
          })
        );
      } else {
        setDuration(max + newFileDuration);
        setFiles(prevState => [...prevState, ...acceptedFiles]);
      }
    }
  };

  const onPathDropAccepted = async acceptedFiles => {
    if (checkValidLoginStatus(userId, dispatch)) {
      setExternalFile({ ...externalFile, path: acceptedFiles[0] });
    }
  };

  const onIndexDropAccepted = async acceptedFiles => {
    if (checkValidLoginStatus(userId, dispatch)) {
      setExternalFile({ ...externalFile, index: acceptedFiles[0] });
    }
  };

  const onDropRejected = async input => {
    if (checkValidLoginStatus(userId, dispatch)) {
      const error = input[0].errors[0];
      if (error.code === 'file-invalid-type') {
        showMessageV2(dispatch, t('trainingTab.modal.notSupportedFileType'));
      }
    }
  };

  const trainDropzone = useDropzone({
    accept: {
      'audio/mp3': ['.mp3'],
      'audio/wav': ['.wav'],
      'audio/mpeg-4': ['.m4a'],
      'audio/flac': ['.flac'],
      'audio/ogg': ['.ogg'],
    },
    onDropAccepted,
    onDropRejected,
    disabled: loading,
  });

  const pathDropzone = useDropzone({
    accept: {
      '*/*': ['.pth'],
    },
    onDropAccepted: onPathDropAccepted,
    onDropRejected,
    disabled: loading,
  });

  const indexDropzone = useDropzone({
    accept: {
      '*/*': ['.index'],
    },
    onDropAccepted: onIndexDropAccepted,
    onDropRejected,
    disabled: loading,
  });

  const handleExternalUpload = async () => {
    try {
      if (checkValidLoginStatus(userId, dispatch)) {
        if (!label) {
          showMessageV2(dispatch, t('trainingTab.modal.noLabel'));
          return;
        } else if (!externalFile.path || !externalFile.index) {
          showMessageV2(dispatch, t('trainingTab.modal.missingFile'));
          return;
        }
        setLoading(true);
        const files = [externalFile.path, externalFile.index];
        await uploadModelMethodOne(userId, files, label);
        await initPage();
      }
    } catch (e) {
      if (e.message) {
        const codeMessage = `code: ${e.code}, message: ${e.message}, config: ${JSON.stringify(e.config)}`;
        await uploadErrorMessage(codeMessage);
      } else {
        await uploadErrorMessage('no message - ' + JSON.stringify(e));
      }
      showMessageV2(dispatch, t('modal.fileUploadFail'));
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async (userId, files, label, subscriptionType, needMdx) => {
    try {
      setLoading(true);
      const uploadError = await uploadTraining(userId, files, label, subscriptionType, needMdx);
      if (!!uploadError) {
        if (uploadError === 'pending') {
          showMessageV2(dispatch, t('modal.trainingInProgressError'), { reloadOnClose: true });
        } else if (uploadError === 'noCount') {
          showMessageV2(dispatch, t('modal.noRemainingError'), { reloadOnClose: true });
        } else {
          showMessageV2(dispatch, t('modal.genericError'), { reloadOnClose: true });
        }
      } else {
        await initPage();
      }
    } catch (e) {
      if (e.message) {
        const codeMessage = `code: ${e.code}, message: ${e.message}, config: ${JSON.stringify(e.config)}`;
        await uploadErrorMessage(codeMessage);
      } else {
        await uploadErrorMessage('no message - ' + JSON.stringify(e));
      }
      showMessageV2(dispatch, t('modal.fileUploadFail'));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async e => {
    if (checkValidLoginStatus(userId, dispatch)) {
      if (user.subscription?.type === 'FREE' && trainingRemainingCount === 0) {
        setOpenFreeTrainingModal(true);
        return;
      }
      let message = '';
      if (trainingInProgress) {
        message = t('trainingTab.submit.inProgress');
      } else if (trainingRemainingCount <= 0) {
        message = t('trainingTab.submit.noRemaining');
      } else if (files.length === 0) {
        message = t('trainingTab.modal.noFile');
      } else if (label.trim().length === 0) {
        message = t('trainingTab.modal.noLabel');
      } else if (label.trim().length > labelLength.voice) {
        message = t('trainingTab.modal.longLabel');
      }
      if (message) {
        showMessageV2(dispatch, message);
        return;
      }

      const durationInMin = Math.ceil(duration / 60);
      if (durationInMin < trainingDuration.min) {
        showMessageV2(
          dispatch,
          t('trainingTab.modal.minDuration', {
            minDuration: trainingDuration.min,
          })
        );
        return;
      }
      await handleUpload(userId, files, label, user.subscription.type, needMdx);
    }
  };

  const handleButtonClick = uploadType => {
    setUploadType(uploadType);
    setNeedMdx(true);
    setExternalFile({});
    setFiles([]);
    setLabel('');
    setDuration(0);
  };

  const classes = useStyles();
  return (
    <>
      <FreeTrainingMessageModal open={openFreeTrainingModal} onClose={() => setOpenFreeTrainingModal(false)} />
      <div className={classes.container}>
        <div className={classes.pageTitle}>{t('trainingTab.title')}</div>
        <div className={classes.section}>
          <div className={`${classes.stepHeader}`}>
            <span className={classes.stepHeaderNum}>01</span>
            {t('trainingTab.stepOne.title')}
            <HtmlTooltip
              title={
                <div className={'globalTooltipContainer'}>
                  <div className={'globalTooltipHeader'}>{t('trainingTab.stepOne.tooltip.titleOne')}</div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>1.</div>
                    <div className={'globalTooltipText'}>{t('trainingTab.stepOne.tooltip.contentsOne.0')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>2.</div>
                    <div className={'globalTooltipText'}>{t('trainingTab.stepOne.tooltip.contentsOne.1')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>3.</div>
                    <div className={'globalTooltipText'}>{t('trainingTab.stepOne.tooltip.contentsOne.2')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>4.</div>
                    <div className={'globalTooltipText'}>{t('trainingTab.stepOne.tooltip.contentsOne.3')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>5.</div>
                    <div className={'globalTooltipText'}>{t('trainingTab.stepOne.tooltip.contentsOne.4')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>6.</div>
                    <div className={'globalTooltipText'}>{t('trainingTab.stepOne.tooltip.contentsOne.5')}</div>
                  </div>
                  <div className={'globalTooltipSubHeaderCenter'}>{t('trainingTab.stepOne.tooltip.titleTwo')}</div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>1.</div>
                    <div className={'globalTooltipText'}>{t('trainingTab.stepOne.tooltip.contentsTwo.0')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>2.</div>
                    <div className={'globalTooltipText'}>{t('trainingTab.stepOne.tooltip.contentsTwo.1')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>3.</div>
                    <div className={'globalTooltipText'}>{t('trainingTab.stepOne.tooltip.contentsTwo.2')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>4.</div>
                    <div className={'globalTooltipText'}>{t('trainingTab.stepOne.tooltip.contentsTwo.3')}</div>
                  </div>
                  <div className={'globalTooltipTextContainer'}>
                    <div className={`globalTooltipText globalTooltipTextBullet`}>5.</div>
                    <div className={'globalTooltipText'}>{t('trainingTab.stepOne.tooltip.contentsTwo.4')}</div>
                  </div>
                </div>
              }
            >
              <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
            </HtmlTooltip>
          </div>

          <div className={classes.uploadContainer}>
            <div className={classes.inputSourceButtonContainer}>
              <div
                className={`${classes.inputSourceButton} ${uploadType == 'train' ? classes.selected : ''}`}
                onClick={() => handleButtonClick('train')}
              >
                {t('trainingTab.tabs.0')}
              </div>
              <div
                className={`${classes.inputSourceButton} ${uploadType == 'external' ? classes.selected : ''}`}
                onClick={() => handleButtonClick('external')}
              >
                {t('trainingTab.tabs.1')}
              </div>
            </div>
            {uploadType === 'train' ? (
              <div className={classes.dragAndDropContainer}>
                <div {...trainDropzone.getRootProps({ style: dragAndDropStyleSameBackground })}>
                  <input {...trainDropzone.getInputProps()} />
                  <div className={classes.dragAndDrop}>
                    <img src={addIcon} className={classes.addButtonImg} />

                    <div className={classes.dragAndDropText}>
                      {t('trainingTab.stepOne.dragAndDropText')}{' '}
                      {t('trainingTab.stepOne.dragAndDropDuration', {
                        maxDuration: trainingDuration.max,
                        minDuration: trainingDuration.min,
                      })}
                    </div>
                  </div>
                </div>

                <div>
                  <List className={classes.dragAndDropList}>
                    {files.map((file, index) => (
                      <ListItem key={index} className={classes.listItem}>
                        <ListItemAvatar className={classes.listItemAvatar}>
                          <Avatar className={classes.avatar}>
                            <AudioIcon className={classes.audioIcon} />
                          </Avatar>
                        </ListItemAvatar>
                        <div className={classes.listItemText}>{file.name}</div>
                        <IconButton
                          onClick={async () => {
                            const newFileList = files.filter((_file, i) => i !== index);
                            setFiles(() => newFileList);
                            setDuration(await getFilesDuration(newFileList));
                          }}
                          edge="end"
                          aria-label="delete"
                        >
                          <DeleteIcon style={{ color: '#fff', width: '20px' }} />
                        </IconButton>
                      </ListItem>
                    ))}
                    <ListItem key={files.length} className={classes.durationText} disableGutters>
                      {t('trainingTab.stepOne.durationText')} : ~{Math.ceil(duration / 60)}{' '}
                      {t('trainingTab.stepOne.durationUnit')}
                    </ListItem>
                  </List>
                </div>
                <div>
                  <FormControl className={classes.radioButtonContainer}>
                    <div className={classes.radioButtonLabel}>
                      {t('trainingTab.stepOne.tabs.fileUpload.vocalExtractionText')}
                      <HtmlTooltip
                        title={
                          <div className={'globalTooltipContainer'}>
                            <div className={'globalTooltipHeader'}>
                              {t('trainingTab.stepOne.tabs.fileUpload.vocalExtractionTooltip.title')}
                            </div>
                            <div className={'globalTooltipTextContainer'}>
                              <div className={`globalTooltipText globalTooltipTextBullet`}>1.</div>
                              <div className={'globalTooltipText'}>
                                {t('trainingTab.stepOne.tabs.fileUpload.vocalExtractionTooltip.contents.0')}
                              </div>
                            </div>
                            <div className={'globalTooltipTextContainer'}>
                              <div className={`globalTooltipText globalTooltipTextBullet`}>2.</div>
                              <div className={'globalTooltipText'}>
                                {t('trainingTab.stepOne.tabs.fileUpload.vocalExtractionTooltip.contents.1')}
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
                      </HtmlTooltip>
                    </div>
                    <Checkbox className={classes.checkbox} checked={!needMdx} onChange={() => setNeedMdx(!needMdx)} />
                  </FormControl>
                </div>
              </div>
            ) : (
              <>
                <div style={{ marginBottom: '20px' }}>
                  <div className={classes.dragAndDropHeader}>PTH File:</div>
                  {externalFile.path && (
                    <div className={classes.acceptedFileOuterContainer}>
                      <div className={classes.acceptedFileInnerContainer}>
                        <div>{externalFile.path.name}</div>
                        <DeleteIcon
                          onClick={() => setExternalFile({ ...externalFile, path: null })}
                          className={classes.acceptedFileDelete}
                        />
                      </div>
                    </div>
                  )}
                  {!externalFile.path && (
                    <>
                      <div
                        {...pathDropzone.getRootProps({
                          style: dragAndDropStyleSameBackground,
                        })}
                      >
                        <input {...pathDropzone.getInputProps()} />
                        <div className={classes.dragAndDrop}>
                          {' '}
                          <img src={addIcon} className={classes.addButtonImg} />
                          <div className={classes.dragAndDropText}>{t('trainingTab.stepOne.dragAndDropText')}</div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div>
                  <div className={classes.dragAndDropHeader}>INDEX File:</div>
                  {externalFile.index && (
                    <div className={classes.acceptedFileOuterContainer}>
                      <div className={classes.acceptedFileInnerContainer}>
                        <div>{externalFile.index.name}</div>
                        <DeleteIcon
                          onClick={() => setExternalFile({ ...externalFile, index: null })}
                          className={classes.acceptedFileDelete}
                        />
                      </div>
                    </div>
                  )}
                  {!externalFile.index && (
                    <div {...indexDropzone.getRootProps({ style: dragAndDropStyleSameBackground })}>
                      <input {...indexDropzone.getInputProps()} />
                      <div className={classes.dragAndDrop}>
                        {' '}
                        <img src={addIcon} className={classes.addButtonImg} />
                        <div className={classes.dragAndDropText}>{t('trainingTab.stepOne.dragAndDropText')}</div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className={classes.section}>
          <div className={`${classes.stepHeader}`}>
            <span className={classes.stepHeaderNum}>02</span>
            <span>{t('trainingTab.stepTwo.title')}</span>
          </div>
          <input
            onChange={e => {
              if (checkValidLoginStatus(userId, dispatch)) {
                setLabel(e.target.value);
              }
            }}
            placeholder={t('trainingTab.stepTwo.placeholder')}
            disabled={loading}
            className={classes.labelInput}
            value={label}
          />
        </div>
        <div className={classes.buttonContainer}>
          {!loading && (
            <div>
              {user && trainingRemainingCount > 0 && (
                <div className={classes.buttonRemainingText}>
                  {t('trainingTab.submit.remainingCountText')} : {trainingRemainingCount}
                </div>
              )}
            </div>
          )}
          {!loading && uploadType == 'external' && (
            <div className={classes.button} onClick={() => handleExternalUpload()}>
              {t('trainingTab.submit.upload')}
            </div>
          )}
          {!loading && uploadType == 'train' && (
            <div className={classes.submitContainer}>
              <HtmlTooltip
                title={
                  <div className={'globalTooltipContainer'}>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('trainingTab.submit.tooltip.contents.0')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('trainingTab.submit.tooltip.contents.1')}</div>
                    </div>
                    <div className={'globalTooltipTextContainer'}>
                      <div className={`globalTooltipText globalTooltipTextBullet`}>&#x2022;</div>
                      <div className={'globalTooltipText'}>{t('trainingTab.submit.tooltip.contents.2')}</div>
                    </div>
                  </div>
                }
              >
                <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
              </HtmlTooltip>
              <div className={classes.button} onClick={handleSubmit}>
                {trainingInProgress && userId
                  ? t('trainingTab.submit.inProgress')
                  : userId && user.subscription?.type !== 'FREE' && trainingRemainingCount <= 0
                    ? t('trainingTab.submit.noRemaining')
                    : t('trainingTab.submit.start')}
              </div>
            </div>
          )}
          {loading && (
            <div className={classes.loadingContainer}>
              <CircularProgress size="1.4rem" />
              <span className={classes.loadingText}>{t('trainingTab.submit.uploading')}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TrainVoicePage;
