import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    padding: '0 2.5rem',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  pageTitle: {
    paddingLeft: '1rem',
    color: '#fff',
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '23px',
    },
    fontSize: '33px',
  },
  pageSubTitle: {
    marginTop: '8px',
    paddingLeft: '1rem',
    fontSize: '0.9rem',
    color: '#fff',
    fontWeight: '300',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  separater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    marginTop: '15px',
  },
  stepHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stepHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem',
    },
    fontSize: '1.6rem',
    marginTop: '1rem !important',
    marginBottom: '1rem',
    color: '#fff',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  scrollButton: {
    border: 'none',
    background: 'none',
    minWidth: '1rem!important',
    minHeight: '1rem!important',
    padding: '0!important',
    '&:disabled': {
      background: 'none',
    },
  },
  disabledArrow: {
    color: 'grey',
    fontSize: '1.8rem!important',
  },
  activeArrow: {
    color: '#fff',
    fontSize: '1.8rem!important',
    cursor: 'pointer',
  },
  subTitleOneContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  scrollContainer: {
    [theme.breakpoints.down('xs')]: {
      gap: '0.53rem',
    },
    display: 'flex',
    gap: '1rem',
    overflowX: 'auto',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  hotCard: {
    [theme.breakpoints.down('xs')]: {
      width: `6rem`,
      height: '6rem',
      fontSize: '1rem',
    },
    flex: '0 0 auto',
    width: `10rem`,
    height: '10rem',
    border: '0.1rem solid #fafafa',
    backgroundColor: '#161616',
    borderRadius: '0.5rem',
    color: '#fff',
    fontSize: '1.5rem',
    fontWeight: '400',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
  },
  hotPlayButtonImg: {
    position: 'absolute',
    zIndex: '3',
    userSelect: 'none',
    cursor: 'pointer',
    color: '#fff',
    top: '0.3rem',
    right: '0.2rem',
    background: 'radial-gradient(circle, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 72%)',
    '&.MuiSvgIcon-root': {
      [theme.breakpoints.down('xs')]: {
        width: '1.5rem',
        height: '1.5rem',
      },
      width: '2rem',
      height: '2rem',
      padding: '0',
      margin: '0',
    },
  },
  hotCoverImg: {
    [theme.breakpoints.down('xs')]: {
      width: `5.83rem`,
      height: '5.83rem',
    },
    position: 'absolute',
    zIndex: '0',
    borderRadius: '0.4rem',
    width: `9.8rem`,
    height: '9.8rem',
  },
  hotCardContent: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.4rem 0.5rem',
    },
    position: 'absolute',
    width: '100%',
    zIndex: '2',
    display: 'flex',
    alightItems: 'center',
    gap: '1rem',
    borderRadius: '0 0 0.5rem 0.5rem',
    bottom: '0',
    backgroundColor: 'rgb(0, 0, 0, 0.6)',
    padding: '0.7rem 0.8rem',
  },
  hotCardLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
      lineHeight: '1.1rem',
    },
    flex: 1,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    overflowWrap: 'anywhere',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardGrid: {
    columnGap: '15px',
  },
  card: {
    display: 'flex',
    border: '1px solid #404040',
    height: '94px',
    backgroundColor: '#161616',
    padding: '.7rem',
    paddingBottom: '.5rem',
    borderRadius: '0.5rem',
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: '400',
    maxWidth: '25rem',
    marginBottom: '1rem',
    minWidth: '310px',
    [theme.breakpoints.down(476)]: {
      fontSize: '0.75rem',
      height: '80px',
      maxWidth: '305px',
      minWidth: '305px',
      padding: '.4rem',
      paddingBottom: '.3rem',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardFirstColumn: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '5px',
  },
  cardSecondColumn: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    overflow: 'hidden',
    width: 'calc(100% - 135px)',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 115px)',
      margin: '4px 0',
    },
  },
  cardThirdColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardLabel: {
    flex: 1,
    marginLeft: '0.3rem',
    fontSize: '16px',
    flexWrap: 'wrap',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(476)]: {
      fontSize: '15px',
    },
  },
  cardUsername: {
    alightItems: 'flex-end',
    display: 'flex',
    marginLeft: '0.3rem',
    flex: 1,
    fontSize: '0.9rem',
    color: '#d3d3d3',
    height: '25px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(476)]: {
      fontSize: '10px',
      height: '18px',
    },
  },
  cardTimestamp: {
    alightItems: 'bottom',
    display: 'flex',
    flex: 1,
    fontSize: '12px',
    color: '#d3d3d3',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down(476)]: {
      fontSize: '10px',
    },
  },
  cardDetails: {
    [theme.breakpoints.down(476)]: {
      lineHeight: '12px',
    },
    lineHeight: '14px',
    display: 'flex',
  },
  cardDetailSeparator: {
    margin: '0 5px',
    fontSize: '12px',
  },
  numberPlayed: {
    color: '#d3d3d3',
    fontSize: '12px',
    [theme.breakpoints.down(476)]: {
      fontSize: '10px',
    },
  },
  creations: {
    color: '#d3d3d3',
    fontSize: '12px',
    marginLeft: '.2rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: '0.07rem',
    [theme.breakpoints.down(476)]: {
      fontSize: '10px',
    },
  },
  searchbar: {
    margin: '0.3rem 0 0.5rem 0',
    width: '25rem',
    maxWidth: '-webkit-fill-available',
    fontSize: '16px !important',
    height: '36px',
    color: '#9a9a9a',
    padding: '18px 10px',
    backgroundColor: '#292929',
    border: '1px solid #404040',
    borderRadius: '8px !important',
    '&:focus': {
      outline: 'none',
    },
  },
  pagination: {
    fontSize: '1.5rem',
    fontWeight: '500',
    color: '#ffffff',
    margin: '1rem 0',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },

    [theme.breakpoints.up('m')]: {
      marginLeft: '50rem',
    },
    [theme.breakpoints.down(1134)]: {
      width: '6.3rem',
    },

    backgroundColor: '#CAFF73',
    padding: '0.6rem 0 0 0',
    margin: '.3rem 0 .5rem 0',
    width: '7.0rem',
    fontSize: '1.1rem',
    borderRadius: '8px',
    textAlign: 'center',
    cursor: 'pointer',
    height: '2.5rem',
    color: '#404040',
    verticalTextAlign: 'center',
  },
  searchButtonContainer: {
    [theme.breakpoints.down('s')]: {
      maxWidth: '25rem',
    },
    [theme.breakpoints.up(1135)]: {
      maxWidth: '51rem',
    },
    [theme.breakpoints.up(1550)]: {
      maxWidth: '77rem',
    },
    [theme.breakpoints.up(1965)]: {
      maxWidth: '103rem',
    },
    maxWidth: '25rem',

    display: 'flex',
    justifyContent: 'space-between',
  },
  coverImg: {
    userSelect: 'none',
    width: '70px',
    height: '70px',
    display: 'inline-block',
    fill: 'currentcolor',
    flexShrink: '1',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    [theme.breakpoints.down('xs')]: {
      width: '60px',
      height: '60px',
    },
  },
  playButtonImg: {
    userSelect: 'none',
    width: '60px !important',
    height: '60px !important',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      width: '40px !important',
      height: '40px !important',
    },
  },
  favoriteImg: {
    userSelect: 'none',
    flexShrink: '0',
    fontSize: '2rem',
    cursor: 'pointer',
    borderRadius: '0.1rem',
    marginRight: '1px'
  },
  favoriteFullImg: {
    userSelect: 'none',
    flexShrink: '0',
    fontSize: '2rem',
    cursor: 'pointer',
    borderRadius: '0.1rem',
    color: '#d32f2f',
    marginRight: '1px'
  },
  numberLikes: {
    marginLeft: '.1rem',
    marginRight: '.1rem',
    fontSize: '12px',
    [theme.breakpoints.down(476)]: {
      fontSize: '10px',
    },
  },
  tagAutoComplete: {
    [theme.breakpoints.down('s')]: {
      padding: '0.2rem 0.5rem!important',
      width: '10rem',
      height: '5rem',
    },
    '&::placeholder': {
      color: '#818181',
      fontSize: '0.9rem',
      fontWeight: '400',
      border: 'none!important',
    },
    width: '25rem',
    maxWidth: '-webkit-fill-available',
    color: '#fff',
    fontSize: '16px!important',
    fontWeight: '400',
    border: '1px solid #404040',
    borderRadius: '8px !important',
    '&:focus': {
      outline: 'none',
    },
    backgroundColor: '#292929',
  },
  sortSelect: {
    backgroundColor: '#fff',
    height: '2.5rem',
    [theme.breakpoints.down(1134)]: {
      width: '6',
    },
    width: '7',
    border: '1px solid #404040',
    borderRadius: '8px !important',
    backgroundColor: '#292929',
    color: '#9a9a9a!important',
    '&::placeholder': {
      color: '#818181',
      fontSize: '0.9rem',
      fontWeight: '400',
      border: 'none!important',
    },
  },
  filterSortContainer: {
    [theme.breakpoints.down('s')]: {
      maxWidth: '25rem',
    },
    [theme.breakpoints.up(1135)]: {
      maxWidth: '51rem',
    },
    [theme.breakpoints.up(1550)]: {
      maxWidth: '77rem',
    },
    [theme.breakpoints.up(1965)]: {
      maxWidth: '103rem',
    },
    maxWidth: '25rem',
    display: 'flex',
    marginBottom: '1rem',
    justifyContent: 'space-between',
  },
}));

export { useStyles };
