import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '730px',
    minWidth: '299px',
    width: '100%',
    alignSelf: 'center',
    marginBottom: '90px',
  },
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '23px',
    },
    fontSize: '33px',
    fontWeight: '400',
    color: '#fff',
    marginBottom: '20px',
  },
  section: {
    [theme.breakpoints.down('xs')]: {
      padding: '20px 15px',
    },
    backgroundColor: '#1c1c1c',
    padding: '20px 30px',
    marginBottom: '20px',
  },
  stepHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    fontSize: '20px',
    marginBottom: '20px',
    color: '#fff',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
  },
  stepHeaderNum: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    },
    fontSize: '25px',
    marginRight: '16px',
  },
  card: {
    display: 'flex',
    backgroundColor: '#292929',
    border: '1px solid #404040',
    borderRadius: '8px',
    height: '76px',
  },
  coverImg: {
    userSelect: 'none',
    width: '76px',
    height: '74px',
    borderRadius: '8px 0 0 8px',
  },
  cardSecondColumn: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 100px)',
      padding: '6px 10px',
    },
    display: 'flex',
    maxWidth: 'calc(100% - 100px)',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '6px 15px',
  },
  cardLabel: {
    fontSize: '16px',
    color: '#ffffff',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
  },
  cardUsername: {
    fontSize: '12px',
    textOverflow: 'ellipsis',
    color: '#cfcfcf',
    marginTop: '8px',
  },
  cardDetails: {
    display: 'flex',
    fontSize: '12px',
    color: '#cfcfcf',
    lineHeight: '13.5px',
  },
  selectModelOuterContainer: {
    width: '100%',
    height: '76px',
    display: 'inline-flex',
    '&:hover': {
      '& $dragAndDropText': {
        color: '#fff',
      },
    },
    border: '1px solid #404040',
    borderRadius: '8px',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: '#292929',
  },
  selectModelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      borderColor: '#fff',
      color: '#fff',
      textColor: '#fff',
      fontSize: '40px',
    },
  },
  addButtonImg: {
    [theme.breakpoints.down('xs')]: {
      width: '21px',
      height: '21px',
    },
    width: '30px',
    height: '30px',
    marginRight: '10px',
  },
  dragAndDropText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    fontWeight: '300',
    fontSize: '16px',
    color: '#ffffff',
    textAlign: 'center',
    marginTop: '2px',
  },
  clearButtonImg: {
    margin: '4px 4px 0 0',
    cursor: 'pointer',
    color: '#ffffff',
    width: '20px!important',
    height: '20px!important',
  },
  ttsTextArea: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    width: '100%',
    fontSize: '16px',
    backgroundColor: 'white',
    color: '#ffffff',
    padding: '12px',
    borderRadius: '8px',
    resize: 'vertical',
    backgroundColor: '#292929',
    '&:focus': {
      outline: 'none',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonRemainingText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    marginBottom: '8px',
    fontSize: '15px',
    color: '#9a9a9a',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px',
      fontSize: '11px',
      minWidth: '100px',
    },
    backgroundColor: '#CAFF73',
    padding: '10px 30px',
    fontSize: '16px',
    textAlign: 'center',
    borderRadius: '8px',
    minWidth: '120px',
    cursor: 'pointer',
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    fontSize: '16px',
    marginLeft: '16px',
    lineHeight: '22px',
    color: '#fff',
    fontWeight: '300',
  },
  settingButton: {
    [theme.breakpoints.down('xs')]: {
      height: '2.5rem',
      fontSize: '0.8rem',
      width: '5.5rem',
    },
    height: '2.8rem',
    width: '7rem',
    backgroundColor: '#141414',
    padding: '0.5rem',
    fontSize: '0.8rem',
    borderRadius: '8px',
    cursor: 'pointer',
    marginRight: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    border: '1px solid #fafafa',
  },
  settingTypo: {
    [theme.breakpoints.down(600)]: {
      fontSize: '0.7rem !important',
      marginLeft: '0.2rem',
    },
    marginLeft: '0.5rem',
    fontSize: '0.8rem !important',
    color: '#fff',
  },
  submitTypo: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
    fontSize: '0.8rem',
  },
  tuneIcon: {
    '& svg': {
      fontSize: '20rem',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '1.3rem',
    },
    color: '#fff',
  },
}));

export { useStyles };
