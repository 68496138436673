import Modal from '@material-ui/core/Modal';
import { useStyles } from './SelectVoiceModal.styles.js';

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactTimeAgo from 'react-time-ago';
import ReactJkMusicPlayer from 'react-jinke-music-player';

import OutlinedInput from '@mui/material/OutlinedInput';
import { ButtonGroup, Button } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CircularProgress from '@material-ui/core/CircularProgress';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { checkValidLoginStatus } from '../../utils/user.utils';
import {
  checkTrainingCompletion,
  fetchTrainingsChunk,
  fetchCommunityItems,
  likeCommunityItem,
  getCommunitySampleSignedUrl,
  fetchPagedCommunityItems,
  fetchPagedCommunityItemsLiked,
  fetchAllTags,
  fetchTaggedCommunityItems,
  dislikeCommunityItem,
  fetchTaggedLikedCommunityItems,
} from '../../services/page.services';

import HtmlInfoIcon from '../HtmlInfoIcon.js';
import infoIconImg from '../../img/infoIcon.png';
import coverImg from '../../img/cover.png';
import { showMessageV2 } from '../../utils/page.utils.js';

export default function SelectVoiceModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //modal
  const { open, loading } = props;

  const user = useSelector(state => state.user);
  const userId = user?.id;
  //alignment
  const [voiceType, setVoiceType] = useState('community');

  //initData
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [communityData, setCommunityData] = useState([]);
  const [filteredCommunityData, setFilteredCommunityData] = useState([]);

  //Search
  const [query, setQuery] = useState('');
  const [communityQuery, setCommunityQuery] = useState('');

  //play
  const [selectedAudio, setSelectedAudio] = React.useState();
  const [playing, setPlaying] = useState(false);
  const [selectedCommunityId, setSelectedCommunityId] = useState('');

  //Tags
  const [selectedTags, setSelectedTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [selectedSortingMethod, setSelectedSortingMethod] = React.useState('creations');

  //pagination
  const chunk = 50;
  const communityChunk = 150;
  const [chunkPage, setChunkPage] = useState(0);
  const [length, setLength] = useState(0);
  const controllerRef = useRef(null);
  const [paginationInfo, setPaginationInfo] = useState({});

  //Run this whenever userId(whenver the page loads for the first time), query, or alignment change
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  useEffect(() => {
    if (voiceType === 'my voices') {
      (async () => {
        await initData(false);
      })();
    }
  }, [userId, query]);

  useEffect(() => {
    if (voiceType === 'my voices') {
      (async () => {
        await initData(false);
      })();
    } else {
      (async () => {
        setCommunityQuery('');
        setSelectedTags([]);
        setSelectedSortingMethod('creations');
      })();
    }
  }, [voiceType]);

  //add event listened to be triggered whenever the played song ends
  useEffect(() => {
    if (selectedAudio) {
      selectedAudio.addEventListener('ended', () => {
        setSelectedCommunityId('');
        setPlaying(false);
      });
    }
    return () => {
      if (selectedAudio) {
        selectedAudio.pause();
        selectedAudio.removeEventListener('ended', () => {
          setSelectedCommunityId = '';
          setPlaying(false);
        });
      }
    };
  }, [selectedAudio]);

  //when people click play button
  useEffect(() => {
    if (selectedAudio) {
      playing ? selectedAudio.play() : selectedAudio.pause();
    }
  }, [playing]);

  //search query for community items
  useEffect(() => {
    handleSearch();
  }, [userId, communityQuery, selectedTags, selectedSortingMethod]);

  const initPublicData = async scroll => {
    //setCommunityQuery("");

    if (!allTags.length) {
      const fetchedAllTags = await fetchAllTags();
      setAllTags(fetchedAllTags);
    }
    //setSelectedTags([]);
    if (voiceType === 'community') {
      const communityItems = await fetchPagedCommunityItems(
        1,
        communityChunk,
        communityQuery,
        selectedTags,
        selectedSortingMethod,
        userId
      );
      setCommunityData(communityItems.rows);
      setFilteredCommunityData(communityItems.rows);
      setPaginationInfo(communityItems.pagination);
    } else {
      if (userId) {
        const communityItems = await fetchPagedCommunityItemsLiked(
          1,
          communityChunk,
          communityQuery,
          selectedTags,
          selectedSortingMethod,
          userId
        );
        setCommunityData(communityItems.rows);
        setFilteredCommunityData(communityItems.rows);
        setPaginationInfo(communityItems.pagination);
      } else {
        setCommunityData([]);
        setFilteredCommunityData([]);
        setPaginationInfo({});
      }
    }
  };

  const fetchMoreData = async () => {
    try {
      if (voiceType === 'community') {
        const communityItems = await fetchPagedCommunityItems(
          paginationInfo.next,
          communityChunk,
          communityQuery,
          selectedTags,
          selectedSortingMethod,
          userId
        );
        setCommunityData([...communityData, ...communityItems.rows]);
        setFilteredCommunityData([...communityData, ...communityItems.rows]);
        setPaginationInfo(communityItems.pagination);
      } else {
        const communityItems = await fetchPagedCommunityItemsLiked(
          paginationInfo.next,
          communityChunk,
          communityQuery,
          selectedTags,
          selectedSortingMethod,
          userId
        );
        setCommunityData([...communityData, ...communityItems.rows]);
        setFilteredCommunityData([...communityData, ...communityItems.rows]);
        setPaginationInfo(communityItems.pagination);
      }
    } catch (err) {
      if (err.name != 'CanceledError') {
        showMessageV2(dispatch, t('historyTab.modal.error'), { reloadOnClose: true });
      }
    }
  };

  const initData = async scroll => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;
    try {
      if (userId) {
        let trainingsChunk;
        if (scroll) {
          trainingsChunk = await fetchTrainingsChunk(chunk, chunkPage, query, true, signal);
        } else {
          trainingsChunk = await fetchTrainingsChunk(chunk, 0, query, true, signal);
        }
        let trainings = trainingsChunk.rows;

        const incompleteList = _.filter(trainings, { status: 'PENDING' });
        if (_.size(incompleteList) > 0) {
          await checkTrainingCompletion(userId, incompleteList);
          if (scroll) {
            trainingsChunk = await fetchTrainingsChunk(chunk, chunkPage, query, signal);
          } else {
            trainingsChunk = await fetchTrainingsChunk(chunk, 0, query, signal);
          }
          trainings = trainingsChunk.rows;
        }

        const cleanedData = trainings.map(({ id, label, status, createdAt, tier, userId }) => {
          return {
            id,
            label,
            createdAt: moment(createdAt).format('MM/DD/YY hh:mm A'),
            status,
            tag: tier === 'FREE' ? `[${t('historyTab.freeVersionTag')}]` : '',
            userId,
          };
        });

        if (scroll) {
          setData([...data, ...cleanedData]);
          setChunkPage(prevChunkPage => prevChunkPage + 1);
        } else {
          setData(cleanedData);
          setChunkPage(1);
        }
        setLength(trainingsChunk.count);
      } else {
        setData([]);
        setChunkPage(0);
        setLength(0);
      }
    } catch (err) {
      console.log(err);
      if (err.name != 'CanceledError') {
        showMessageV2(dispatch, t('historyTab.modal.error'), { reloadOnClose: true });
      }
    }
  };

  //sortingFunction used to sort voice cards
  const sortingFunction = (a, b) => {
    if (selectedSortingMethod === 'creations') {
      return Number(b.numberUsed) - Number(a.numberUsed);
    } else if (selectedSortingMethod === 'label') {
      const aLabel = a.label ? a.label : '';
      const bLabel = b.label ? b.label : '';
      return aLabel.localeCompare(bLabel);
    } else if (selectedSortingMethod === 'likes') {
      return Number(b.likedNumber) - Number(a.likedNumber);
    } else {
      return moment(b.createdAt).diff(moment(a.createdAt));
    }
  };
  /*

  const searchCommunityItems = searchValue => {
    if (voiceType === 'community') {
      if (searchValue === '') {
        //setCommunityQuery(searchValue);
        setFilteredCommunityData(communityData);
      } else {
        //setCommunityQuery(searchValue);
        setFilteredCommunityData(
          communityData.filter(item => {
            return Object.values(item).join('').toLowerCase().includes(communityQuery.toLowerCase());
          })
        );
      }
    } else if (voiceType === 'favorites') {
      if (searchValue === '') {
        //setCommunityQuery(searchValue);
        setFilteredCommunityData(communityItemsLiked);
      } else {
        //setCommunityQuery(searchValue);
        setFilteredCommunityData(
          communityItemsLiked.filter(item => {
            return Object.values(item).join('').toLowerCase().includes(communityQuery.toLowerCase());
          })
        );
      }
    }
  };
*/
  //check if selected language is korean or not
  const translator = option => {
    if (t('languageDetector') === 'ko') {
      return option.korean;
    } else {
      return option.name;
    }
  };

  const getBlobFromS3Url = async url => {
    const res = await fetch(url);
    return res.blob();
  };

  const handleSearch = _.debounce(initPublicData, 300);

  const handleSortChange = async event => {
    setSelectedSortingMethod(event.target.value);
  };

  const handleLike = async communityItemId => {
    if (checkValidLoginStatus(user.id, dispatch)) {
      likeCommunityItem(communityItemId);
      const copiedData = communityData.map(obj => {
        if (obj.id === communityItemId) {
          return {
            ...obj,
            likedNumber: Number(obj.likedNumber) + 1,
            liked: true,
          };
        }
        return obj;
      });

      const copiedFilteredData = filteredCommunityData.map(obj => {
        if (obj.id === communityItemId) {
          return {
            ...obj,
            likedNumber: Number(obj.likedNumber) + 1,
            liked: true,
          };
        }
        return obj;
      });

      setCommunityData(copiedData);
      setFilteredCommunityData(copiedFilteredData);
    }
  };

  const handleDislike = async communityItemId => {
    dislikeCommunityItem(communityItemId);
    const copiedData = communityData.map(obj => {
      if (obj.id === communityItemId) {
        let newObj = { ...obj };
        newObj.likedNumber = Number(obj.likedNumber) - 1;
        newObj.liked = false;

        return newObj;
      }
      return obj;
    });

    const copiedFilteredData = filteredCommunityData.map(obj => {
      if (obj.id === communityItemId) {
        let newObj = { ...obj };
        newObj.likedNumber = Number(obj.likedNumber) - 1;
        newObj.liked = false;
        return newObj;
        //return { ...obj, likedNumber: obj.likedNumber - 1 };
      }
      return obj;
    });

    setCommunityData(copiedData);
    setFilteredCommunityData(copiedFilteredData);
  };

  const handlePlay = async (communityItemId, label) => {
    if (selectedCommunityId === communityItemId) {
      setPlaying(true);
    } else {
      setPlaying(false);
      const sampleVoiceUrl = await getCommunitySampleSignedUrl(communityItemId);
      const sampleVoiceBlob = await getBlobFromS3Url(sampleVoiceUrl);
      if (sampleVoiceBlob.size > 2000) {
        const audio = new Audio(URL.createObjectURL(sampleVoiceBlob.slice(0, sampleVoiceBlob.size, `audio/mp3`)));

        setSelectedAudio(audio);
        setPlaying(true);
        setSelectedCommunityId(communityItemId);
      }
    }
  };

  const handlePlay2 = _.debounce(handlePlay, 500, { leading: true });

  const handlePause = async (communityItemId, label) => {
    setPlaying(false);
  };

  //when a user clicks the voice card
  /*
  const onClick = (res) => {
    setPlaying(false);
    if (res) {
      props.onSelectVoice(label);
    } else props.onSelectVoiceModalClose();
  };
  */

  //when a user clicks the voice card

  const handleSelectVoice = (model, type) => {
    setPlaying(false);
    props.onSelectVoice(model, type);
  };

  return (
    <Modal
      open={!!open}
      onClose={() => {
        setPlaying(false);
        props.onSelectVoiceModalClose();
      }}
      disableEnforceFocus
      className={`${classes.modal} ${fontStyle}`}
    >
      <div id="scrollableDiv" className={classes.container}>
        {!loading && (
          <>
            <div className={classes.pageTitle}>{t('communityTab.title')}</div>
            <div className={classes.separater} />

            <ButtonGroup className={classes.inputSourceButtonContainer}>
              <Button
                className={`${classes.inputSourceButton} ${voiceType === 'community' ? classes.selected : ''}`}
                style={{ fontSize: 'inherit' }}
                onClick={() => setVoiceType('community')}
              >
                {t('communityTab.voiceOptions.0')}
              </Button>
              <Button
                className={`${classes.inputSourceButton} ${voiceType === 'my voices' ? classes.selected : ''}`}
                style={{ fontSize: 'inherit' }}
                onClick={() => setVoiceType('my voices')}
              >
                {t('communityTab.voiceOptions.1')}
              </Button>
              <Button
                className={`${classes.inputSourceButton} ${voiceType === 'favorites' ? classes.selected : ''}`}
                style={{ fontSize: 'inherit' }}
                onClick={() => setVoiceType('favorites')}
              >
                {t('communityTab.voiceOptions.2')}
              </Button>
            </ButtonGroup>

            {voiceType === 'my voices' && (
              <div className={classes.searchButtonContainer}>
                <Grid item xs={12} sm={12} md={12}>
                  <input
                    className={classes.searchbar}
                    placeholder={t('historyTab.searchbar')}
                    value={query}
                    onChange={query => setQuery(query.target.value)}
                  />
                </Grid>
              </div>
            )}

            {(voiceType === 'community' || voiceType === 'favorites') && (
              <div className={classes.searchButtonContainer}>
                <Grid item xs={12} sm={12} md={12}>
                  <input
                    className={classes.searchbar}
                    placeholder={t('historyTab.searchbar')}
                    value={communityQuery}
                    onChange={newValue => setCommunityQuery(newValue.target.value)}
                  />
                </Grid>
              </div>
            )}
            {(voiceType === 'community' || voiceType === 'favorites') && (
              <Grid className={classes.filterSortContainer} container>
                <Grid item xs={7} sm={7} md={7}>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    size="small"
                    className={classes.tagAutoComplete}
                    value={selectedTags}
                    options={allTags}
                    groupBy={option => option.type}
                    getOptionLabel={option => translator(option)}
                    onChange={(event, newValues) => {
                      setSelectedTags(newValues);
                    }}
                    renderInput={params => <TextField {...params} placeholder={t('communityTab.tags')} />}
                  />
                </Grid>
                <Grid container item xs={4} sm={4} md={4} display="flex" direction="row-reverse">
                  <FormControl
                    size="small"
                    className={classes.sortSelect}
                    sx={theme => ({
                      width: '7rem',
                      [theme.breakpoints.down(1134)]: {
                        width: '6.3rem',
                      },
                      borderRadius: '0.2rem',
                      fontSize: '1rem',
                    })}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedSortingMethod}
                      devaultValue={'creations'}
                      label="test"
                      onChange={handleSortChange}
                    >
                      <MenuItem value={'creations'}>{t('communityTab.sortOptions.2')}</MenuItem>
                      <MenuItem value={'recent'}>{t('communityTab.sortOptions.0')}</MenuItem>
                      <MenuItem value={'likes'}>{t('communityTab.sortOptions.1')}</MenuItem>

                      <MenuItem value={'label'}>{t('communityTab.sortOptions.3')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}

            {voiceType === 'my voices' && (
              <InfiniteScroll
                className={classes.infiniteScroll}
                dataLength={data.length}
                next={() => initData(true)}
                hasMore={data.length < length}
                loader={
                  <div className={classes.pagination} key={0}>
                    {t('historyTab.pagination.loader')}
                  </div>
                }
                scrollableTarget="scrollableDiv"
              >
                <Grid container xs={12} sm={12} md={12} className={classes.cardGrid}>
                  {data
                    .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
                    .map(
                      ({
                        id,
                        label,
                        createdAt,
                        status,
                        tag,
                        outputFormat,
                        sourceLabel,
                        trainingLabel,
                        userId,
                        username,
                        image,
                      }) => {
                        return (
                          <Grid
                            className={classes.card}
                            item
                            container
                            key={id}
                            onClick={() =>
                              handleSelectVoice(
                                {
                                  id,
                                  label,
                                  createdAt,
                                  status,
                                  trainingLabel,
                                  userId,
                                  username: user.name,
                                },
                                'my voices'
                              )
                            }
                          >
                            <Grid item xs="auto" sm={3} md={3}>
                              <img className={classes.coverImg} src={image ? image : coverImg} alt="cover-img" />
                            </Grid>
                            <Grid
                              container
                              className={classes.cardSecondColumn}
                              //style={{ border: "1px solid #fafafa" }}
                              item
                              xs
                              sm={9}
                              md={9}
                            >
                              <Grid container item>
                                <Grid item xs={10} sm={10} md={10}>
                                  <div className={classes.cardLabel}>{label}</div>
                                </Grid>
                                <Grid
                                  container
                                  item
                                  className={classes.playButtonGrid}
                                  xs={2}
                                  sm={2}
                                  md={2}
                                  //style={{ border: "1px solid #fafafa" }}
                                ></Grid>
                              </Grid>
                              <Grid container item>
                                <div className={classes.cardUsername}>{user.name}</div>
                              </Grid>
                              <Grid container item>
                                <Grid
                                  container
                                  item
                                  xs={7}
                                  sm={7}
                                  md={7}
                                  style={{
                                    alignItems: 'flex-end',
                                    maxHeight: '2rem',
                                  }}
                                >
                                  <div
                                    className={classes.cardTimestamp}
                                    style={{
                                      marginBottom: '0.2rem',
                                    }}
                                  >
                                    <ReactTimeAgo date={createdAt} />
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }
                    )}
                </Grid>
              </InfiniteScroll>
            )}

            {(voiceType === 'community' || (userId && voiceType === 'favorites')) && filteredCommunityData && (
              <InfiniteScroll
                className={classes.infiniteScroll}
                dataLength={filteredCommunityData.length}
                next={fetchMoreData}
                hasMore={paginationInfo.has_next}
                loader={<div className={classes.pagination}>{t('historyTab.pagination.loader')}</div>}
                scrollableTarget="scrollableDiv"
              >
                <Grid container className={classes.cardGrid}>
                  {filteredCommunityData.map(
                    ({
                      id,
                      gender,
                      label,
                      username,
                      createdAt,
                      status,
                      trainingLabel,
                      image,
                      likedNumber,
                      users,
                      numberUsed,
                      liked,
                    }) => {
                      return (
                        <Grid
                          className={classes.card}
                          item
                          container
                          key={id}
                          onClick={() =>
                            handleSelectVoice(
                              {
                                id,
                                gender,
                                username,
                                image,
                                likedNumber,
                                label,
                                createdAt,
                                status,
                                trainingLabel,
                                userId,
                                liked,
                              },
                              'community'
                            )
                          }
                        >
                          <Grid item xs="auto" sm={3} md={3}>
                            <img className={classes.coverImg} src={image ? image : coverImg} alt="cover-img" />
                          </Grid>
                          <Grid
                            container
                            className={classes.cardSecondColumn}
                            //style={{ border: "1px solid #fafafa" }}
                            item
                            xs
                            sm={9}
                            md={9}
                          >
                            <Grid container item>
                              <Grid container item xs={10} sm={10} md={10}>
                                <div className={classes.cardLabel}>{label}</div>
                              </Grid>

                              <Grid
                                container
                                item
                                className={classes.playButtonGrid}
                                xs={2}
                                sm={2}
                                md={2}
                                //style={{ border: "1px solid #fafafa" }}
                              >
                                {playing && id === selectedCommunityId ? (
                                  <PauseCircleOutlineIcon
                                    className={classes.playButtonImg}
                                    onClick={event => {
                                      event.stopPropagation();
                                      handlePause(id, label);
                                    }}
                                  />
                                ) : (
                                  <PlayCircleOutlineIcon
                                    className={classes.playButtonImg}
                                    onClick={event => {
                                      event.stopPropagation();
                                      handlePlay2(id, label);
                                    }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                            <Grid container item>
                              <div className={classes.cardUsername}>{username}</div>
                            </Grid>
                            <Grid
                              container
                              item
                              style={{
                                maxHeight: '2rem',
                              }}
                            >
                              <Grid
                                className={classes.creationsGrid}
                                container
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                style={{
                                  maxHeight: '1.1rem',
                                }}
                              >
                                <div className={classes.numberPlayed}>{numberUsed}</div>
                                <div className={classes.creations}>{'creations'}</div>
                              </Grid>
                              <Grid
                                container
                                item
                                xs={5}
                                sm={5}
                                md={5}
                                style={{
                                  alignItems: 'flex-end',
                                  maxHeight: '1.1rem',
                                }}
                              >
                                <div className={classes.cardTimestamp}>
                                  <ReactTimeAgo date={createdAt} />
                                </div>
                              </Grid>
                              <Grid
                                container
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                className={classes.cardLikes}
                                style={{
                                  maxHeight: '2rem',
                                }}
                              >
                                {!liked && (
                                  <FavoriteBorderRoundedIcon
                                    className={classes.favoriteImg}
                                    onClick={event => {
                                      event.stopPropagation();
                                      handleLike(id);
                                    }}
                                    fontSize="inherit"
                                  />
                                )}
                                {liked && (
                                  <FavoriteIcon
                                    className={classes.favoriteFullImg}
                                    onClick={event => {
                                      event.stopPropagation();
                                      handleDislike(id);
                                    }}
                                    fontSize="inherit"
                                  />
                                )}

                                <div className={classes.numberLikes}>{likedNumber}</div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </InfiniteScroll>
            )}
          </>
        )}
        {loading && <CircularProgress className={classes.loading} />}
      </div>
    </Modal>
  );
}
