import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useStyles } from './GenerateTTSPage.styles';

import _ from 'lodash';
import ReactJkMusicPlayer from 'react-jinke-music-player';
import 'react-jinke-music-player/assets/index.css';
import ReactTimeAgo from 'react-time-ago';

import CircularProgress from '@material-ui/core/CircularProgress';
import TuneIcon from '@mui/icons-material/Tune';
import ClearIcon from '@mui/icons-material/Clear';

import {
  checkTrainingCompletion,
  fetchSubscriptionInfo,
  checkTTSInferenceCompletion,
  uploadTTSInference,
  fetchTrainings,
} from '../../services/page.services';

import { checkValidLoginStatus } from '../../utils/user.utils';
import { appActions } from '../../actions/app.actions.js';
import { userActions } from '../../actions/user.actions.js';
import { getDefaultModel } from '../../constants/model.constants';
import SelectTTSVoiceModal from '../layout/SelectTTSVoiceModal';
import TTSSettingModal from '../layout/TTSSettingModal.js';

import coverImg from '../../img/cover.png';
import FreeInferenceMessageModal from '../layout/FreeInferenceMessageModal.js';
import { showMessageV2 } from '../../utils/page.utils.js';
import addIcon from '../../img/addIcon.png';

function GenerateTTSPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const app = useSelector(state => state.app);
  const userId = user?.id;
  const isAdmin = user?.subscription?.type === 'ADMIN';
  const isCustom = user?.subscription?.type === 'CUSTOM';

  const intervalIDRef = React.useRef(null);
  const [loadingText, setLoadingText] = useState('');

  const [selectedVoiceType, setSelectedVoiceType] = useState(app.selectedModel ? 'community' : '');
  const [selectedModel, setSelectedModel] = useState(app.selectedModel);

  const [text, setText] = useState('');
  const [ttsCharactersRemaining, setTtsCharactersRemaining] = useState(0);
  const [ttsCharactersPerInference, setTtsCharactersPerInference] = useState(20);
  const [inferenceInProgress, setInferenceInProgress] = useState(false);

  const [selectVoiceModalProps, setSelectVoiceModalProps] = useState({});
  const [completedAudio, setCompletedAudio] = React.useState();
  const [openFreeInferenceModal, setOpenFreeInferenceModal] = useState(false);

  const [variability, setVariability] = useState(50);
  const [similarity, setSimilarity] = useState(75);
  const [settingModalProps, setSettingModalProps] = useState({
    variability: variability,
    similarity: similarity,
    setVariability: setVariability,
    setSimilarity: setSimilarity,
  });

  //play
  const [selectedAudio, setSelectedAudio] = React.useState();
  const [playing, setPlaying] = useState(false);
  const [selectedCommunityId, setSelectedCommunityId] = useState('');

  useEffect(() => () => intervalIDRef.current && clearInterval(intervalIDRef.current), []);

  useEffect(() => {
    return () => {
      dispatch(appActions.clearSelectedCommunityItem());
    };
  }, []);

  useEffect(() => {
    if (userId) (async () => initPage())();
  }, [userId]);

  //add event listened to be triggered whenever the played song ends
  useEffect(() => {
    if (selectedAudio) {
      selectedAudio.addEventListener('ended', () => {
        setSelectedCommunityId('');
        setPlaying(false);
      });
    }
    return () => {
      if (selectedAudio) {
        selectedAudio.pause();
        selectedAudio.removeEventListener('ended', () => {
          setSelectedCommunityId('');
          setPlaying(false);
        });
      }
    };
  }, [selectedAudio]);

  //when people click play button
  useEffect(() => {
    if (selectedAudio) {
      playing ? selectedAudio.play() : selectedAudio.pause();
    }
  }, [playing]);

  const defaultModel = getDefaultModel(t);

  const checkInferenceInProgress = async () => {
    let inferences = await checkTTSInferenceCompletion();
    const inProgress = _.some(inferences, { status: 'PENDING' });
    setInferenceInProgress(inProgress);
  };

  const getBlobFromS3Url = async url => {
    const res = await fetch(url);
    return res.blob();
  };

  const initPage = async () => {
    try {
      if (!isAdmin && !isCustom) {
        await checkInferenceInProgress();
      }
      await checkRemainingCount();
      await checkTrainingCompletion();
      const trainings = await fetchTrainings();
      const communityTrainings = await fetchTrainings();
      const availableTraining = _.sortBy(
        _.map(_.filter(trainings, { status: 'COMPLETE' }), v => ({
          ...v,
          category: 'my voices',
        })),
        ['label']
      );
      const availableCommunityTraining = _.sortBy(
        _.map(_.filter(communityTrainings, { status: 'COMPLETE' }), v => ({
          ...v,
          category: 'community',
        })),
        ['label']
      );
      const completeTrainingList = availableTraining.concat(availableCommunityTraining);
    } catch (e) {
      console.log(e);
      showMessageV2(dispatch, t('modal.pageLoadFail'), { reloadOnClose: true });
    }
  };

  const checkRemainingCount = async () => {
    let subscriptionInfo = await fetchSubscriptionInfo();
    dispatch(userActions.updateSubscription(subscriptionInfo));
    console.log('subscriptionInfo: ', subscriptionInfo);
    setTtsCharactersRemaining(subscriptionInfo?.ttsCharactersRemaining || 0);
    setTtsCharactersPerInference(subscriptionInfo?.ttsCharactersPerInference || 20);
    return subscriptionInfo;
  };

  const checkCompleteForm = () => {
    let message = '';
    if (inferenceInProgress) {
      message = t('ttsInferenceTab.modal.inProgress');
    } else if (text.length > ttsCharactersRemaining) {
      message = t('ttsInferenceTab.modal.noRemaining');
    } else if (text.length > ttsCharactersPerInference) {
      message = t('ttsInferenceTab.modal.exceedTextSize', {
        characterLimitPerInference: ttsCharactersPerInference,
      });
    } else if (!selectedModel?.id) {
      message = t('ttsInferenceTab.modal.noModalSelected');
    } else if (text.length === 0) {
      message = t('ttsInferenceTab.modal.noText');
    }
    if (message) {
      showMessageV2(dispatch, message);
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    if (!checkValidLoginStatus(userId, dispatch)) return;
    if (!checkCompleteForm()) return;
    setCompletedAudio(null);
    setLoadingText(t('ttsInferenceTab.submit.uploading'));
    setInferenceInProgress(true);
    const label = `${selectedModel.label} - ${text.substring(0, 25)}`;

    console.log(`selectedModel = ${selectedModel}`);
    try {
      const inferenceBlob = await uploadTTSInference(
        userId,
        text,
        selectedModel,
        variability,
        similarity,
        user.subscription.type,
        label
      );
      setLoadingText(t('ttsInferenceTab.submit.inProgress'));
      console.log(inferenceBlob);
      setCompletedAudio(
        _.compact([
          inferenceBlob && {
            name: label,
            musicSrc: URL.createObjectURL(inferenceBlob.slice(0, inferenceBlob.size, `audio/mpeg`)),
            extension: 'mp3',
          },
        ])
      );
      setLoadingText('');
      checkRemainingCount();
      setInferenceInProgress(false);
    } catch (e) {
      showMessageV2(dispatch, t('ttsInferenceTab.submit.tryAgain'));
      setLoadingText('');
      checkRemainingCount();
      setInferenceInProgress(false);
      return;
    }
  };

  const customDownloader = downloadInfo => {
    const downloadingAudio = _.find(completedAudio, {
      musicSrc: downloadInfo.src,
    });
    const link = document.createElement('a');
    link.href = downloadInfo.src;
    link.download = `${downloadingAudio.name}.${downloadingAudio.extension}`;
    document.body.appendChild(link);
    link.click();
  };

  const onBeforeDestroy = () => {
    return new Promise((_resolve, reject) => {
      setCompletedAudio(null);
      reject();
    });
  };

  const handleSelectVoice = async () => {
    if (!checkValidLoginStatus(userId, dispatch)) return;
    setSelectVoiceModalProps({
      open: true,
      onSelectVoice: async (selectedVoice, selectedVoiceType) => {
        //setSelectVoiceModalProps({ open: true, loading: true });
        setSelectedModel(selectedVoice);
        setSelectedVoiceType(selectedVoiceType);
        setSelectVoiceModalProps({ open: false });
      },
      onSelectVoiceModalClose: () => {
        setSelectVoiceModalProps({ open: false });
      },
    });
  };

  const handleSetting = async () => {
    setSettingModalProps({
      open: true,
      setVariability: setVariability,
      setSimilarity: setSimilarity,
      variability: variability,
      similarity: similarity,
      onSettingModalClose: () => {
        setSettingModalProps({ open: false });
      },
    });
  };

  console.log(' ttsCharactersRemaining : ', ttsCharactersRemaining);
  const classes = useStyles();
  return (
    <>
      <FreeInferenceMessageModal open={openFreeInferenceModal} setOpenFreeInferenceModal={setOpenFreeInferenceModal} />
      <SelectTTSVoiceModal {...selectVoiceModalProps} />
      <TTSSettingModal {...settingModalProps} />
      <div className={classes.container}>
        <div className={classes.pageTitle}>{t('ttsInferenceTab.title')}</div>
        <div className={classes.section}>
          <div className={`${classes.stepHeader}`}>
            <span className={classes.stepHeaderNum}>01</span>
            <span>{t('ttsInferenceTab.stepOne.title')}</span>
          </div>
          {selectedModel && (
            <div className={classes.card} key={selectedModel.id}>
              <img
                className={classes.coverImg}
                src={selectedModel.image ? selectedModel.image : coverImg}
                alt="cover-img"
              />
              <div className={classes.cardSecondColumn}>
                <div className={classes.cardLabel}>{selectedModel.label}</div>
                <div className={classes.cardUsername}>{selectedModel.username || ''}</div>

                <div className={classes.cardDetails}>
                  <ReactTimeAgo date={selectedModel.createdAt} locale="en-US" />
                </div>
              </div>

              <ClearIcon
                onClick={() => {
                  setSelectedCommunityId('');
                  setPlaying(false);
                  setSelectedModel(null);
                }}
                className={classes.clearButtonImg}
              />
            </div>
          )}
          {!selectedModel && (
            <div className={classes.selectModelOuterContainer} onClick={() => handleSelectVoice()}>
              <div className={classes.selectModelContainer}>
                <img src={addIcon} className={classes.addButtonImg} />
                <div className={classes.dragAndDropText}>{t('ttsInferenceTab.stepOne.dragAndDropText')}</div>
              </div>
            </div>
          )}
        </div>
        <div className={classes.section}>
          <div className={`${classes.stepHeader}`}>
            <span className={classes.stepHeaderNum}>02</span>
            <span>{t('ttsInferenceTab.stepTwo.title')}</span>
          </div>

          <textarea
            className={classes.ttsTextArea}
            placeholder={t('ttsInferenceTab.stepOne.ttsTextAreaPlaceholder')}
            rows={16}
            value={text}
            onChange={e => {
              if (checkValidLoginStatus(userId, dispatch)) {
                setText(e.target.value);
              }
            }}
          />
        </div>
        <div className={classes.section}>
          <div className={`${classes.stepHeader}`}>
            <span className={classes.stepHeaderNum}>03</span>
            <span>{t('ttsInferenceTab.stepThree.title')}</span>
          </div>
          <div
            className={classes.settingButton}
            onClick={() => {
              if (checkValidLoginStatus(userId, dispatch)) {
                handleSetting();
              }
            }}
          >
            <TuneIcon fontSize="20rem" className={classes.tuneIcon} />
            <div className={classes.settingTypo}>{t('ttsInferenceTab.submit.setting')}</div>
          </div>
        </div>

        {!loadingText && (
          <div className={classes.buttonContainer}>
            <div>
              <div className={classes.buttonRemainingText}>
                {text.length} / {ttsCharactersPerInference}
                {' ' + t('ttsInferenceTab.submit.remainingCountUnit')}
              </div>
              {userId && ttsCharactersRemaining > 0 && (
                <div item className={classes.buttonRemainingText}>
                  {t('ttsInferenceTab.submit.remainingMonthly')}: {ttsCharactersRemaining}
                </div>
              )}
            </div>
            <div>
              <div className={classes.submitContainer}>
                <div className={classes.button} onClick={handleSubmit} disabled={inferenceInProgress}>
                  <div className={classes.submitTypo}>
                    {inferenceInProgress
                      ? t('ttsInferenceTab.submit.alreadyInProgress')
                      : t('ttsInferenceTab.submit.start')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {loadingText && (
          <div className={classes.loadingContainer}>
            <CircularProgress size="1.4rem" />
            <span className={classes.loadingText}>{loadingText}</span>
          </div>
        )}
        {completedAudio && (
          <ReactJkMusicPlayer
            mode="full"
            theme="light"
            autoPlay={false}
            showPlayMode={false}
            showThemeSwitch={false}
            showMiniModeCover={false}
            autoHiddenCover
            onBeforeDestroy={onBeforeDestroy}
            spaceBar={true}
            showDestroy={true}
            responsive={false}
            showReload={false}
            toggleMode={false}
            remove={false}
            customDownloader={customDownloader}
            audioLists={completedAudio}
          />
        )}
      </div>
    </>
  );
}

export default GenerateTTSPage;
